/* eslint-disable react/prop-types */
/* eslint-disable no-cond-assign */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-constant-condition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function MultInput({ value, onChange, placeholder }) {
  const [state, setState] = useState({ tags: [], isInital: true });
  const inputRef = useRef();
  useEffect(() => {
    if (value.length && state.isInital) {
      setState({ ...state, tags: [...state.tags, ...value], isInital: false });
    }
    // else if (state.isInital) {
    //   setState({ ...state, isInital: false });
    // }
  }, [value]);
  //   const addTags = (event) => {
  //     event.stopPropagation();
  //     event.preventDefault();
  //     if (
  //       (event.key === 'Enter' || event.key === ',' || event.key === ' ') &&
  //       event.target.value !== ''
  //     ) {
  //       let value = event.target.value;
  //       if ((event.key = ',')) {
  //         value = value.replace(',', '');
  //       }
  //       value = value.trim();
  //       if (value !== '') {
  //         const currentValue = [...tags, value];
  //         setTags(currentValue);
  //         if (onChange) {
  //           onChange(currentValue);
  //         }
  //         event.target.value = '';
  //       }
  //     }
  //     return false;
  //   };
  const addValue = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const curvalue = inputRef.current?.value;
    if (curvalue !== '') {
      let value = curvalue;
      //   if ((event.key = ',')) {
      //     value = value.replace(',', '');
      //   }
      value = value.trim();
      if (value !== '') {
        const currentValue = [...state.tags, value];
        setState({ ...state, tags: currentValue, isInital: false });
        if (onChange) {
          onChange(currentValue);
        }
        inputRef.current.value = '';
      }
    }
    return false;
  };
  const removeTags = (index) => {
    const { tags } = state;
    const tagSet = [...tags.filter((tag) => tags.indexOf(tag) !== index)];
    setState({ ...state, tags: tagSet });
    if (onChange) {
      onChange(tagSet);
    }
  };
  return (
    <div className="col-lg-12 md-2 flex_gap">
      <ul className="d-flex flex-row flex-wrap">
        {state.tags.map((tag, index) => (
          <li
            key={index}
            style={{
              overflow: 'hidden',
              whitespace: 'nowrap',
              borderRadius: '4px',
              backgroundColor: 'rgb(155, 150, 150)',
              fontSize: '85%',
              padding: '3px 3px 0px 3px',
              boxSizing: ' border-box',
              color: 'white',
              margin: '3px',
              cursor: 'default',
            }}
          >
            <span> {tag}</span>
            <span
              style={{
                marginLeft: '3px',
                marginRight: '0px',
                height: '20px',
                fontWeight: 'bold',
                width: '10px',
                cursor: 'pointer',
              }}
              onClick={() => removeTags(index)}
            >
              X
            </span>
          </li>
        ))}
      </ul>
      <input
        type="text"
        ref={inputRef}
        // highlight-next-line
        // onKeyUp={(event) => addValue(event)}
        placeholder={placeholder}
      />
      <button type="button" onClick={(event) => addValue(event)}>
        add +
      </button>
    </div>
  );
}
MultInput.prototype = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
MultInput.defaultProps = {
  value: [],
  placeholder: 'Press space or enter key to add text',
};
export default React.memo(MultInput);
