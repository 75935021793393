import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function TextAreaField({
  fieldName,
  className,
  classNamedark,
  label,
  labelstyle,
  labeldarkstyle,
  register,
  errors,
  placeHolder,
  isRequired,
  minimLength,
  maxLength,
  mandatory,
  resetCount,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  // const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
  useEffect(() => {
    if (resetCount) {
      // ChangeTextAreaCount(0);
    }
  }, [resetCount]);
  return (
    <div className="form-field">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label}
      </label>
      {mandatory || isRequired ? <span style={{ color: 'red' }}>*</span> : <span />}
      <textarea
        placeholder={placeHolder ?? 'Type Here'}
        className={classNamess ?? className}
        maxLength={maxLength}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName, {
          // onChange: (e) => {
          //   ChangeTextAreaCount(e.target.value.length);
          // },
          required: {
            value: isRequired,
            message: `${fieldName} is required`,
          },
          minLength: {
            value: minimLength,
            message: `Value must be minimum ${minimLength} `,
          },
          maxLength: {
            value: maxLength,
            message: `Value must be maxlength ${maxLength} `,
          },
        })}
      />
      {/* {textAreaCount !== 0 && (
        <p style={{ float: 'right', fontSize: '12px' }}>
          {'Characters Left : '}
          {maxLength - textAreaCount}
        </p>
      )}
      {maxLength - textAreaCount === 0 ? (
        <span style={{ color: 'red' }}>Maximum length accepted is {maxLength}</span>
      ) : (
        <span />
      )} */}
      <p style={{ color: 'red' }}>
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

TextAreaField.propTypes = {
  fieldName: PropTypes.element.isRequired,
  className: PropTypes.string,
  classNamedark: PropTypes.string,
  labelstyle: PropTypes.element.isRequired,
  label: PropTypes.element,
  register: PropTypes.element.isRequired,
  errors: PropTypes.element.isRequired,
  placeHolder: PropTypes.element,
  isRequired: PropTypes.element,
  minimLength: PropTypes.element,
  maxLength: PropTypes.element,
  mandatory: PropTypes.element,
  labeldarkstyle: PropTypes.element,
  resetCount: PropTypes.bool,
};
TextAreaField.defaultProps = {
  mandatory: false,
  isRequired: false,
  classNamedark: null,
  labeldarkstyle: null,
  label: 'Text Area',
  placeHolder: 'Enter Value',
  minimLength: null,
  maxLength: null,
  resetCount: false,
  className: 'default-input-class',
};
export default TextAreaField;
