/* eslint-disable arrow-body-style */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
// import styles from './fileInput.module.scss';

function FileInput({ fieldName, control, rules, allowedTypes, fieldTitle }) {
  const [state, setState] = useState({ file: null });
  let handleOnchangeRefFunc;
  const handleDeleteCurrentSelectedImage = () => {
    setState({ file: null });
    if (handleOnchangeRefFunc) {
      handleOnchangeRefFunc(undefined);
    }
  };
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field: { value, onChange } }) => {
        handleOnchangeRefFunc = onChange;
        useEffect(() => {
          if (value && !state.file) setState({ ...state, file: value });
        }, [value]);
        return (
          <div>
            <label htmlFor={fieldName}>{fieldTitle}</label>
            {!state.file && (
              <input
                accept={allowedTypes}
                value={value?.fileName}
                placeholder="choose file"
                onReset={() => {
                  setState({ ...state, file: null });
                }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const fileExtention = file.name.split('.').reverse()[0];
                    if (allowedTypes.includes(`.${fileExtention}`)) {
                      onChange(file);
                      setState({ ...state, file });
                    }
                  }
                }}
                type="file"
                id={fieldName}
              />
            )}
            {state.file && (
              <>
                <img
                  src={
                    typeof state.file === 'string' ? state.file : URL.createObjectURL(state.file)
                  }
                  width={250}
                  height={250}
                  alt="pic"
                />
                <button type="button" onClick={handleDeleteCurrentSelectedImage}>
                  x
                </button>
              </>
            )}
          </div>
        );
      }}
    />
  );
}
FileInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  rules: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allowedTypes: PropTypes.oneOfType([PropTypes.array]),
  fieldTitle: PropTypes.string.isRequired,
};
FileInput.defaultProps = {
  allowedTypes: ['.jpg', '.jpeg', '.png'],
};
export default FileInput;
