/* eslint-disable no-underscore-dangle */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from '../../commoncss/index.module.scss';
import { REACT_APP_API_FETCH_CHAPLAIN_CHANGE_REQUEST } from '../../redux/apiConstants';
import IndexTable from '../../components/IndexTable/IndexTable';

const ChaplainChange = React.memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dateFormater = (date) => {
    const dateInput = moment(date);
    return dateInput.format('LLL');
  };
  return (
    <div className={styles.indexdiv}>
      <IndexTable
        urlParam={REACT_APP_API_FETCH_CHAPLAIN_CHANGE_REQUEST}
        viewPopUp={false}
        section="chaplainchange"
        dashTitle="Chaplain Change Request"
        // toggleButton={{ show: true, field: 'active', uri: USER_CHANGE_STATUS, mode: 'popup' }}
        deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
        hiddenList={['add', 'edit', 'delete']}
        formatter={{ time: dateFormater }}
      />
    </div>
  );
});

export default ChaplainChange;
