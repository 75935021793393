/* eslint-disable react/function-component-definition */
import React, { useEffect } from 'react';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styles from '../../commoncss/index.module.scss';
import {
  CHAPLAIN_CHANGE_STATUS,
  REACT_APP_API_FETCH_SESSION_HISTORY,
} from '../../redux/apiConstants';
// import PasswordChange from '../../components/PasswordChange/PasswordChange';
import ExportTable from '../../components/ExportTable/ExportTable';

const SessionHistoryIndex = React.memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dateFormater = (date) => {
    const dateInput = moment(date);
    return dateInput.format('LLL');
  };
  return (
    <div className={styles.indexdiv}>
      <ExportTable
        urlParam={REACT_APP_API_FETCH_SESSION_HISTORY}
        viewPopUp={false}
        section="Session History"
        toggleButton={{ show: false, field: 'active', uri: CHAPLAIN_CHANGE_STATUS }}
        deleteCondition={{ checkCondition: false, apiUrl: 'www.google.com' }}
        hiddenList={['add', 'edit']}
        formatter={{ startTime: dateFormater, endTime: dateFormater }}
        showSearch={false}
        // additionalComponent={[PasswordChange]}
      />
    </div>
  );
});
export default SessionHistoryIndex;
