/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// import * as XLSX from 'xlsx';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function ExportCommon({ apiUrl, fileName, format, handlerFunc }) {
  //   const exportToCSV = (appendUrl, name) => () => {
  //     const url = `${SERVER_BASE_URL}/${appendUrl}`; // api url
  //     fetch(url)
  //       .then((resp) => resp.json()) // calling url by method GET
  //       .then((resp) => {
  //         if (resp.result.length) {
  //           // eslint-disable-next-line no-underscore-dangle
  //           const cleanArray = resp.result.map((item) => {
  //             // eslint-disable-next-line no-underscore-dangle
  //             delete item.id;
  //             return item;
  //           });
  //           const ws = XLSX.utils.json_to_sheet(cleanArray);
  //           const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  //           XLSX.writeFile(wb, `${name}sheetjs.xlsx`);
  //         }
  //       }); // setting response to state posts
  //   };
  const exportFile = (apiurl, name) => {
    try {
      axios
        .get(`${apiurl}?name=${name}&format=${format}`, {
          responseType: 'blob', // Important
        })
        .then((response) => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          // Set the download attribute with a filename
          link.setAttribute('download', `export.${format}`); // e.g., 'file.pdf'

          // Append the link to the body
          document.body.appendChild(link);

          // Simulate a click on the link
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };
  const getExportString = () => {
    switch (format) {
      case 'xlsx':
        return 'Export Excel';
      case 'pdf':
        return 'Export Pdf';
      case 'csv':
        return 'Export Csv';
      default:
        return 'Export Excel';
    }
  };
  return (
    <li>
      <div
        className="dropdown-item"
        onClick={() => {
          if (handlerFunc) handlerFunc(apiUrl, fileName, format);
          else exportFile(apiUrl, fileName);
        }}
      >
        {getExportString()}
      </div>
    </li>
  );
}

ExportCommon.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  format: PropTypes.string,
  handlerFunc: PropTypes.func,
};
ExportCommon.defaultProps = {
  format: 'xlsx',
  handlerFunc: undefined,
};

export default ExportCommon;
