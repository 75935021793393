/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TableContext } from '../../contexts/tableContext';
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from '../ActionsButtons/ActionButtons';
import Pagination from '../Pagination/Pagination';
import { tabledelete } from '../../redux/TableDeleteSlice';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
import styles from './IndexTable.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getCookies } from '../../hooks/useCookies';
import CustomToggle from '../CustomToggle/customToggle';
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter';
import ExportToExcel from '../Export/ExportToExcel';
import ExportPdf from '../ExportPdf/ExportPdf';
import { REACT_APP_API_EXPORTING_SESSION_HISTORY } from '../../redux/apiConstants';
import ExportCommon from '../Export/ExportCommon';

const ExportTable = React.memo(
  ({
    urlParam,
    viewPopUp,
    toggleButton,
    section,
    deleteCondition,
    isMultiSelect,
    hiddenList,
    formatter,
    additionalComponent,
    showSearch,
  }) => {
    let url;
    const today = moment();
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { setDashboardHeader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const { pageNum, setPageNum } = useContext(TableContext);
    // new
    const tablelength = tableData.length;
    const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies('Token')}`;
    const [sorting, setSorting] = useState([{ col: 'none', sortType: 'none' }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [deletedData, setDeletedData] = useState(null);
    const [exportDate, setExportDate] = useState({
      startDate: today.clone().subtract(1, 'week'),
      endDate: today.clone().subtract(1, 'day'),
    });
    const [selected, setselected] = useState([]);
    const [tableConfig, setTableConfig] = useState();
    const [tableLoading, setTableLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const dataPerPage = 5;
    const params = useParams();
    let response;
    let statusCode;
    let docId;
    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      setselected((oldArray) => [...oldArray, id]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= checkedState.length; i++) {
        if (checkedState[position] === true) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < selected.length; j++) {
            if (selected[j] === id) {
              selected.splice(j, 1);
            }
            // const newselected = selected.filter((item) => item === id);
            // setselected(newselected);
          }
        }
      }
    };
    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create`);
    };
    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };
    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      docId = params.chplainId;
      url += `page=${pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== 'none') {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        url += `&searchBy=${search}`;
      }
      if (exportDate.startDate) {
        url += `&id=${docId}&start=${exportDate.startDate.toISOString()}&end=${exportDate.endDate.toISOString()}`;
      }
      axios
        .get(url, {
          method: 'GET',
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            response = res.data.data.result;
            setTableData(response.results);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            const { Displayfields } = response;
            if (Array.isArray(Displayfields)) {
              setColumns(Displayfields);
            } else {
              const colkeys = Object.keys(Displayfields);
              setTableConfig(Displayfields);
              setColumns(colkeys);
            }
          }
        })
        .catch((err) => {
          setTableLoading(false);
          return err;
        });
    };
    const deleteHandleFunc = (val) => {
      setDeletedData(val);
    };
    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);
    useEffect(() => {
      setDashboardHeader(section);
      const temp = section.replace(/\s+/g, '').toLowerCase();
      const permissions = getCookies('USERPERMISSION');
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(element.view || element.edit || element.create || element.delete),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [pageNum, search, sorting, deletedData, exportDate.startDate]);
    const setSort = (param) => () => {
      setPageNum(1);
      switch (sorting.sortType) {
        case 'none':
          setSorting({ col: param, sortType: 'asc' });
          break;
        case 'asc':
          setSorting({ col: param, sortType: 'desc' });
          break;
        case 'desc':
          setSorting({ col: param, sortType: 'none' });
          break;
        default:
          setSorting({ col: param, sortType: 'asc' });
          break;
      }
    };
    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.length > 0) {
            setPageNum(1);
            setSearchTerm(even.target.value);
          } else {
            setSearchTerm(null);
          }
        }, 1000)
      );
    };

    const onDateChange = (ranges) => {
      // ranges ...
      if (!moment(ranges.startDate).isSame(ranges.endDate)) {
        setOpen(false);
        const start = moment(ranges.startDate).startOf('day');
        const end = moment(ranges.endDate).endOf('day');
        setExportDate((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
      } else if (ranges.startDate === '' && ranges.endDate === '') {
        setOpen(false);
      }
    };

    const exportFile = (apiurl, name, format) => {
      try {
        docId = params.chplainId;
        const exportUrl = `${apiurl}?name=${name}&id=${docId}&format=${format}&start=${exportDate.startDate.toISOString()}&end=${exportDate.endDate.toISOString()}`;
        axios
          .get(exportUrl, {
            responseType: 'blob',
            headers: {
              Authorization: token,
            },
          })
          .then((reData) => {
            // Create a URL for the blob
            const downloadurl = window.URL.createObjectURL(new Blob([reData.data]));
            const link = document.createElement('a');
            link.href = downloadurl;

            // Set the download attribute with a  filename
            link.setAttribute('download', `${name}.${format}`); // e.g., 'file.pdf'

            // Append the link to the body
            document.body.appendChild(link);

            // Simulate a click on the link
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    };
    return (
      <div className={styles[bodyStyle]}>
        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: '100vh' }}
            id={styles.dashboardcont2}
          >
            <div className="container-fluid" id={styles.container}>
              <div className="col-md-">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  value="Back"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className={styles.arrowback}
                />
              </div>
              <div className="row align-items-center ">
                <div className="col-md-6">
                  <DateRangeFilter
                    onChange={onDateChange}
                    open={open}
                    setOpen={setOpen}
                    maxRange={new Date()}
                  />
                </div>
                <div className="col-md-6">
                  <div className={styles.exportsection}>
                    <div className="dropdown exp_bt" id={styles.dropdown}>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Export
                      </button>
                      <ul
                        className="dropdown-menu"
                        id={styles.dropdown_menu}
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <ExportCommon
                          apiUrl={REACT_APP_API_EXPORTING_SESSION_HISTORY}
                          format="csv"
                          fileName="Session History"
                          handlerFunc={exportFile}
                        />
                        <ExportCommon
                          apiUrl={REACT_APP_API_EXPORTING_SESSION_HISTORY}
                          format="xlsx"
                          fileName="Session History"
                          handlerFunc={exportFile}
                        />
                        <ExportCommon
                          apiUrl={REACT_APP_API_EXPORTING_SESSION_HISTORY}
                          format="pdf"
                          fileName="Session History"
                          handlerFunc={exportFile}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {showSearch && (
                    <div className={styles.search_box}>
                      <input
                        className={styles[searchboxStyle]}
                        id={styles.searchbar}
                        type="search"
                        onChange={onFilterTextChange}
                        placeholder="Search"
                        // value={search}
                        readOnly={tableLoading}
                      />
                      <div
                        className={tableLoading ? 'spinner-border spinner-border-sm' : ''}
                        id={styles.spinner}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {/* <h5 className={styles[bodyheader]} id={styles.tableheading}>
                  {section} Data
                </h5> */}
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12" />
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className={styles.sectionright}>
                    {addButtonPermissions.delete && selected.length > 0 && (
                      <div className="dropdown" id={styles.dropdown}>
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <ul
                          className="dropdown-menu"
                          id={styles.dropdown_menu}
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                confirmAlert({
                                  title: '',
                                  message: 'Are you sure, you want to delete.',
                                  buttons: [
                                    {
                                      label: 'Yes',
                                      onClick: () => {
                                        dispatch(tabledelete(selected)).then(() => {
                                          setDeletedData(`data deleted ${selected}`);
                                          window.location.reload(false);
                                        });
                                      },
                                    },
                                    {
                                      label: 'No',
                                      // onClick: () => alert("Click No")
                                    },
                                  ],
                                });
                              }}
                            >
                              Delete{' '}
                              <i className="fa fa-trash" aria-hidden="true" id={styles.del_icon} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                    {addButtonPermissions.create &&
                      (hiddenList && hiddenList.includes('add') ? null : (
                        <button
                          className={styles.tablebtn}
                          type="button"
                          onClick={() => {
                            actionAdd();
                          }}
                        >
                          {' '}
                          <FontAwesomeIcon icon={faPlus} className={styles.add_icon} />
                          Add
                        </button>
                      ))}
                  </div>
                </div>
              </div>
              <div className="table-responsive" style={{ minHeight: '250px' }}>
                <table className="table table-borderless">
                  {columns.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {addButtonPermissions.delete && isMultiSelect && (
                          <th scope="col">
                            <input
                              type="checkbox"
                              id="custom-checkbox"
                              // name={data.id}
                              // value={data.id}
                              checked={checkedAll}
                              onChange={() => handleOnChangeAll()}
                            />
                          </th>
                        )}
                        {columns.map((item) => (
                          <th scope="col">
                            {' '}
                            {tableConfig
                              ? tableConfig[item].alias.toUpperCase()
                              : item.toUpperCase()}
                            {tableConfig ? (
                              tableConfig[item].isSort && (
                                <button
                                  className={styles.sortbtn}
                                  type="button"
                                  aria-label="Sort button"
                                  onClick={setSort(item)}
                                >
                                  {sorting.sortType === 'asc' && sorting.col === item ? (
                                    <i className="fa fa-arrow-up" />
                                  ) : sorting.sortType === 'desc' && sorting.col === item ? (
                                    <i className="fa fa-arrow-down" />
                                  ) : (
                                    <i className="fa fa-sort" />
                                  )}
                                </button>
                              )
                            ) : (
                              <button
                                className={styles.sortbtn}
                                type="button"
                                aria-label="Sort button"
                                onClick={setSort(item)}
                              >
                                {sorting.sortType === 'asc' && sorting.col === item ? (
                                  <i className="fa fa-arrow-up" />
                                ) : sorting.sortType === 'desc' && sorting.col === item ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-sort" />
                                )}
                              </button>
                            )}
                          </th>
                        ))}
                        {/* <th scope="col">ACTIONS</th> */}
                      </tr>
                    </thead>
                  )}
                  {Object.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr className={styles[tablebodyStyle]}>
                          {addButtonPermissions.delete && isMultiSelect && (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() => handleOnChange(item.id, index)}
                                />
                              </div>
                            </th>
                          )}
                          {columns.map((ele) => (
                            <td>
                              {ele === toggleButton.field ? (
                                toggleButton.mode === 'popup' ? (
                                  <CustomToggle
                                    value={item[ele]}
                                    id={item.id}
                                    placeholders={['Active', 'Inactive']}
                                    URL={toggleButton.uri}
                                  />
                                ) : (
                                  <ToggleButtonOnOff
                                    value={item[ele]}
                                    id={item.id}
                                    placeholders={['Active', 'Inactive']}
                                    URL={toggleButton.uri}
                                  />
                                )
                              ) : typeof item[ele] === 'boolean' ? (
                                <h6 className="order_date"> String(item[ele])</h6>
                              ) : formatter && Object.keys(formatter).includes(ele) ? (
                                formatter[ele](item[ele])
                              ) : (
                                item[ele]
                              )}
                            </td>
                          ))}
                          {/* <td>
                            <ActionButtons
                              data={item}
                              viewPopUp={viewPopUp}
                              section={section}
                              notifyCallback={deleteHandleFunc}
                              hiddenList={hiddenList}
                              additionalComponent={additionalComponent}
                            />
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <h6>Loading...</h6>
                  )}
                  {tableData.length === 0 && !tableLoading ? (
                    <h6 className={styles.norecord}>No record found</h6>
                  ) : (
                    ''
                  )}
                </table>
              </div>
              <Pagination paginationData={paginationData} setPageNum={setPageNum} />
              <br />
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
);
ExportTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  hiddenList: PropTypes.oneOfType([PropTypes.string]),
  formatter: PropTypes.oneOfType([PropTypes.object]),
  additionalComponent: PropTypes.oneOfType([PropTypes.element]),
  showSearch: PropTypes.bool,
};
ExportTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none', uri: 'none' },
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  section: 'User',
  urlParam: null,
  isMultiSelect: false,
  hiddenList: undefined,
  formatter: undefined,
  additionalComponent: null,
  showSearch: true,
};
export default ExportTable;
