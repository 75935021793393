import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;
export const REACT_APP_API_FETCH_CHAPLAIN = `${envValues.REACT_APP_API_ENDPOINT}/chaplain?`;
export const REACT_APP_API_FETCH_APPUSER = `${envValues.REACT_APP_API_ENDPOINT}/appuser?`;
export const CHAPLAIN_CHANGE_STATUS = `${SERVER_BASE_URL}/chaplain/change-status`;
export const USER_CHANGE_STATUS = `${SERVER_BASE_URL}/appuser/change-status`;
export const REACT_APP_API_FETCH_REPORTS = `${SERVER_BASE_URL}/reports?`;
export const REACT_APP_CHANGE_USER_PASSWORD = `${SERVER_BASE_URL}/common/change-password`;
export const REACT_APP_API_FETCH_SESSION_HISTORY = `${envValues.REACT_APP_API_ENDPOINT}/chaplain/session-history?`;
export const REACT_APP_API_EXPORTING_SESSION_HISTORY = `${envValues.REACT_APP_API_ENDPOINT}/chaplain/session-history/export`;
export const REACT_APP_API_FETCH_CHAPLAIN_CHANGE_REQUEST = `${SERVER_BASE_URL}/chaplain/change-request?`;
export const REACT_APP_API_FETCH_CONTACT_REQUEST = `${envValues.REACT_APP_API_ENDPOINT}/contactrequest?`;
export const REACT_APP_API_FETCH_PAYMENT_HISTORY = `${envValues.REACT_APP_API_ENDPOINT}/paymenthistory?`;
