import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import stylesx from '../../commoncss/create.module.scss';
import styles from './ToggleButton.module.scss';
import CustomPopup from '../CustomPopup/CustomPopup';
import SelectBox from '../SelectBox/SelectBox';
import TextAreaField from '../TextArea/TextArea';
import { getCookies } from '../../hooks/useCookies';

function CustomToggle({ value, id, URL }) {
  const [isOff, setIsOff] = useState(false);
  const [state, setState] = useState({ isShowPopup: false, isSubmiting: false });
  useEffect(() => {
    setIsOff(value);
  }, [value]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const suspnedResons = [
    { label: 'Violating Community Guidelines', value: 'Violating Community Guidelines' },
    { label: 'Spam and Misuse', value: 'Spam and Misuse' },
    { label: 'Inappropriate Behavior', value: 'Inappropriate Behavior' },
    { label: 'False Information', value: 'False Information' },
    {
      label: ' Non-Compliance with Platform Terms of Service',
      value: ' Non-Compliance with Platform Terms of Service',
    },
    {
      label: 'Privacy Violations and Other Reasons',
      value: 'Privacy Violations and Other Reasons',
    },
  ];

  const handlepopupClose = () => {
    setState({ ...state, isShowPopup: false, isSubmiting: false });
  };

  const onchangeStatus = () => {
    const token = getCookies('Token');
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    const body = { userid: id, active: !isOff };
    axios.post(URL, body, { headers }).then((result) => {
      const { data: resData } = result;
      if (resData.success) {
        setIsOff(!isOff);
      }
    });
  };

  const handleButtonClick = () => {
    if (!isOff) {
      onchangeStatus();
    } else {
      setState({ ...state, isShowPopup: true });
    }
  };

  const onSubmitdata = (data) => {
    setState({ ...state, isSubmiting: true });
    const token = getCookies('Token');
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    const { suspentionreson, details } = data;
    const body = { userid: id, active: !isOff, reson: suspentionreson.value, details };
    axios.post(URL, body, { headers }).then((result) => {
      const { data: resData } = result;
      if (resData.success) {
        const { active } = resData.data;
        if (!active) {
          toast.success('account suspended successfully');
        }
        setState({ ...state, isSubmiting: false, isShowPopup: false });
        setIsOff(!isOff);
        reset();
      }
    });
  };

  return (
    <div className="col ">
      <label className={styles.switch}>
        <input type="checkbox" checked={isOff} onClick={handleButtonClick} />
        <span className={styles.slider} />
      </label>
      {state.isShowPopup && (
        <CustomPopup handleClose={handlepopupClose}>
          <div>
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <Row>
                <Col lg={6}>
                  <div id={styles.empdiv}>
                    <SelectBox
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      name="suspentionreson"
                      label="Reason for suspension"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={suspnedResons}
                      errors={errors}
                      placeholder="Choose Reson"
                      isRequired
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div id={stylesx.empdiv}>
                    <TextAreaField
                      fieldName="details"
                      label="Details"
                      className={stylesx.inputbox}
                      classNamedark={stylesx.inputbox1}
                      register={register}
                      errors={errors}
                      placeHolder="Enter Details"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  {' '}
                  <input
                    className={stylesx.formbtn}
                    type="submit"
                    disabled={state.isSubmiting}
                    value={state.isSubmiting ? 'Please wait...' : 'Submit'}
                  />
                </Col>
              </Row>
            </form>
          </div>
        </CustomPopup>
      )}
    </div>
  );
}

CustomToggle.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  URL: PropTypes.string,
};
CustomToggle.defaultProps = {
  value: false,
  id: null,
  URL: null,
};
export default CustomToggle;
