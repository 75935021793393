/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { toast } from 'react-toastify';
import axios from 'axios';
import { SpinnerDotted } from 'spinners-react';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
import PhoneNumberInput from '../../components/PhoneNumberBox/PhoneNumberBox';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import styles from '../../commoncss/edit.module.scss';
// import PasswordField from '../../components/PasswordField/PasswordField';
import TextAreaField from '../../components/TextArea/TextArea';
import FileInput from '../../components/FileInput/FileInput';
import SelectBox from '../../components/SelectBox/SelectBox';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import TimeSelected from '../../components/TimeSelect/TimeSelected';
import envValues from '../../enviornment';
import { getCookies } from '../../hooks/useCookies';
import MultInput from '../../components/MultiInput/MultiInput';

const ChaplainEdit = React.memo(() => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    isLoading: false,
    selTime: undefined,
    isAllreadyShown: { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false },
  });
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const params = useParams();
  let chplainId;
  const timezone = [
    { label: '(UTC-05:00) Eastern Time', value: 'US/Eastern' },
    { label: '(UTC-06:00) Central Time', value: 'US/Central' },
    { label: '(UTC-07:00) Mountain Time', value: 'US/Mountain' },
    { label: '(UTC-09:00) Alaska', value: 'US/Alaska' },
    { label: '(UTC-10:00) Hawaii', value: 'US/Hawaii' },
    { label: '(UTC-08:00) Pacific Time', value: 'US/Pacific' },
  ];
  const daysOfweek = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
  ];
  const dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    setValue,
    setError: errorSetter,
    formState: { errors },
    watch,
  } = useForm();
  function processForUpdating(data) {
    const seletArray = [];
    data.forEach((item) => {
      const { day, slot } = item;
      const obj = {};
      seletArray[day] = obj;
      const timeArr = [];
      obj['item-0'] = timeArr;
      slot.forEach((sitem) => {
        const id = parseInt(`${Math.random()}`.split('.')[1], 10);
        const { start, end } = sitem;
        timeArr[id] = { start, end };
      });
    });
    return seletArray;
  }
  const getUserDetails = async () => {
    let statusCode;
    const token = `Bearer ${getCookies('Token')}`;
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/chaplain/edit-info/${chplainId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          const { data } = res.data;
          setState({ ...state, loading: false, data });
          // reset(data);
        } else {
          setState({ ...state, loading: false });
        }
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        return err;
      });
  };
  const selectedDaysWatch = watch('days', false);
  watch((data, { name }) => {
    const { days, selectedTimes } = data;
    if (name === 'days' && days) {
      const removeArray = [];
      if (selectedTimes && Array.isArray(selectedTimes)) {
        selectedTimes.forEach((item, index) => {
          if (!days.includes(index)) {
            removeArray[index] = selectedTimes[index];
          }
        });
        const removeTrack = {};
        if (removeArray.length) {
          removeArray.forEach((its, idx) => {
            let key = `selectedTimes.${idx}`;
            const subArr = removeArray[idx];
            if (subArr['item-0']) {
              key = `${key}.item-0`;
              const atomArr = subArr['item-0'];
              Object.keys(atomArr).forEach((index) => {
                const startKey = `${key}.${index}.start`;
                const endKey = `${key}.${index}.end`;
                unregister(startKey);
                unregister(endKey);
              });
            }
            removeTrack[idx] = true;
          });
          setState({ ...state, isAllreadyShown: { ...state.isAllreadyShown, ...removeTrack } });
        }
      }
    }
  });

  function processData(data) {
    const SelectedTimes = [];
    data.forEach((its, idx) => {
      const Obj = {};
      Obj.day = idx;
      const subArr = its;
      if (subArr['item-0']) {
        Obj.slot = [];
        const atomArr = subArr['item-0'];
        Object.keys(atomArr).forEach((index) => {
          let a = 10;
          let b = 20;
          if (atomArr[index]) {
            const curItem = atomArr[index];
            if (!curItem.start || !curItem.end) {
              a = b;
              b = a;
            } else {
              Obj.slot.push(curItem);
            }
          }
        });
      }
      SelectedTimes.push(Obj);
    });
    return SelectedTimes;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Chaplain');
    chplainId = params.chplainId;
    setState({ ...state, isLoading: true });
    getUserDetails().then(() => {});
  }, []);

  useEffect(() => {
    const { data } = state;
    if (data) {
      if (data.selectedTimes) {
        const selTimes = processForUpdating(data.selectedTimes);
        setState({ ...state, selTime: selTimes });
      }
      delete data.selectedTimes;
      reset(data);
    }
  }, [state.data]);
  function onSubmitdata(sdata) {
    // console.log('ccccc=>', sdata);
    setSubmitting(true);
    setError(null);
    const apiData = {
      fullName: sdata.name || sdata.fullName,
      email: sdata.email,
      phone: sdata.phone,
      // password: data.password,
      bio: sdata.bio,
      education: JSON.stringify(sdata.education),
      faith: JSON.stringify(sdata.faith),
      preferedDays: sdata.days,
      timezone: sdata.timezone.value || sdata.timezone,
    };
    if (sdata.image && typeof sdata.image !== 'string') {
      apiData.File = [sdata.image];
    }
    if (sdata.selectedTimes) {
      apiData.selectedTimes = JSON.stringify(processData(sdata.selectedTimes));
    }
    sdata.actionUrl = `chaplain/${params.chplainId}`;
    sdata.actionMethod = 'patch';
    sdata.apiData = apiData;
    sdata.arrayFields = ['preferedDays'];
    dispatch(Entry(sdata)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Chaplain edited successful');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        const { data: resData } = resp.payload;
        setError(resData.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {state.isLoading ? (
            <div
              style={{
                textAlign: 'center',
                marginTop: '160px',
                marginBottom: '160px',
                color: '#7a68c2',
              }}
            >
              <SpinnerDotted style={{ color: '#39979d' }} />
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row mb-4">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Name"
                      fieldName="fullName"
                      placeHolder="Enter Name"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      charactersOnly
                    />
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <EmailInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      fieldName="email"
                      label="Email"
                      register={register}
                      errors={errors}
                      isRequired
                      placeHolder="Email"
                      mandatory
                    />
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <PhoneNumberInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      placeHolder="Phone Number"
                      label="Phone Number"
                      fieldName="phone"
                      register={register}
                      errors={errors}
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="education">Educational Qualification</label>
                    <Controller
                      control={control}
                      name="education"
                      render={({ field: { onChange } }) => (
                        <MultInput
                          onChange={onChange}
                          value={getValues('education')}
                          placeholder="Enter Educational Qualification"
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="faith">Faith</label>
                    <Controller
                      control={control}
                      name="faith"
                      render={({ field: { onChange } }) => (
                        <MultInput
                          onChange={onChange}
                          value={getValues('faith')}
                          placeholder="Enter Faith"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <TextAreaField
                      fieldName="bio"
                      label="Bio"
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      register={register}
                      errors={errors}
                      placeHolder="Enter Bio"
                      maxLength={300}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 choose_upload">
                    <FileInput
                      control={control}
                      fieldName="image"
                      fieldTitle="Display Picture"
                      rules={undefined}
                      setError={errorSetter}
                    />
                    {errors.image && <p style={{ color: 'red' }}>{errors.image}</p>}
                  </div>
                </div>
                <div className="row  mb-4">
                  <div className="col-lg-12">Available time slots</div>
                </div>
                <div className="row  mb-4">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <SelectBox
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      name="timezone"
                      label="Time zone"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={timezone}
                      errors={errors}
                      placeholder="Choose Timezone"
                      isRequired
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <MultiSelect
                      name="days"
                      label="Days of week"
                      control={control}
                      values={daysOfweek}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="row  mb-4">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {selectedDaysWatch &&
                      selectedDaysWatch.map((item) => {
                        const foudItem = daysOfweek.find((x) => x.value === item);
                        return (
                          <TimeSelected
                            item={foudItem}
                            register={register}
                            key={item}
                            unregister={unregister}
                            errors={errors}
                            trigger={trigger}
                            getValues={getValues}
                            setError={errorSetter}
                            value={state.selTime}
                            setValue={setValue}
                            actionStatus={state.isAllreadyShown}
                            // actionPerfomer={performAction}
                          />
                        );
                      })}
                  </div>
                </div>
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait...' : 'Submit'}
                />
                <input
                  className={styles.formbtn}
                  type="button"
                  value="Cancel"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ChaplainEdit;
