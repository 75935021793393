/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { toast } from 'react-toastify';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
import PhoneNumberInput from '../../components/PhoneNumberBox/PhoneNumberBox';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import styles from '../../commoncss/create.module.scss';
import PasswordField from '../../components/PasswordField/PasswordField';
// import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import TextAreaField from '../../components/TextArea/TextArea';
import FileInput from '../../components/FileInput/FileInput';
import SelectBox from '../../components/SelectBox/SelectBox';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import MultInput from '../../components/MultiInput/MultiInput';

const ChaplainCreate = React.memo(() => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const [formIsReset, setFormIsReset] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Chaplain');
  }, []);
  const timezone = [
    { label: '(UTC-05:00) Eastern Time', value: 'US/Eastern' },
    { label: '(UTC-06:00) Central Time', value: 'US/Central' },
    { label: '(UTC-07:00) Mountain Time', value: 'US/Mountain' },
    { label: '(UTC-09:00) Alaska', value: 'US/Alaska' },
    { label: '(UTC-10:00) Hawaii', value: 'US/Hawaii' },
    { label: '(UTC-08:00) Pacific Time', value: 'US/Pacific' },
  ];
  const daysOfweek = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
  ];
  const regexPattern = /^(?=.*[!@#$%^&*()_+}{:;"'?><,./[\]\\=-])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

  const dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    control,
    getValues,
    trigger,
    setError: errorSetter,
    formState: { errors },
    watch,
  } = useForm();
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'selectedTime',
  // });
  const selectedDaysWatch = watch('days', false);
  watch((data, { name }) => {
    const { days, selectedTimes } = data;
    if (name === 'days' && days) {
      const removeArray = [];
      if (selectedTimes && Array.isArray(selectedTimes)) {
        selectedTimes.forEach((item, index) => {
          if (!days.includes(index)) {
            removeArray[index] = selectedTimes[index];
          }
        });
        if (removeArray.length) {
          removeArray.forEach((its, idx) => {
            let key = `selectedTimes.${idx}`;
            const subArr = removeArray[idx];
            if (subArr['item-0']) {
              key = `${key}.item-0`;
              const atomArr = subArr['item-0'];
              Object.keys(atomArr).forEach((index) => {
                const startKey = `${key}.${index}.start`;
                const endKey = `${key}.${index}.end`;
                unregister(startKey);
                unregister(endKey);
              });
            }
          });
        }
      }
    }
  });
  function processData(data) {
    const SelectedTimes = [];
    data.forEach((its, idx) => {
      const Obj = {};
      Obj.day = idx;
      const subArr = its;
      if (subArr['item-0']) {
        Obj.slot = [];
        const atomArr = subArr['item-0'];
        Object.keys(atomArr).forEach((index) => {
          let a = 10;
          let b = 20;
          if (atomArr[index]) {
            const curItem = atomArr[index];
            if (!curItem.start || !curItem.end) {
              a = b;
              b = a;
            } else {
              Obj.slot.push(curItem);
            }
          }
        });
      }
      SelectedTimes.push(Obj);
    });
    return SelectedTimes;
  }
  function onSubmitdata(data) {
    console.log('cccccx=>', data);
    setSubmitting(true);
    setError(null);
    const apiData = {
      fullName: data.name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      bio: data.bio,
      preferedDays: data.days,
      timezone: data.timezone.value,
      education: JSON.stringify(data.education),
      faith: JSON.stringify(data.faith),
    };
    const arrayFields = ['preferedDays'];
    if (data.image) {
      apiData.File = [data.image];
    }
    // if (data.education) {
    //   apiData.education = data.education;
    //   arrayFields.push('education');
    // }
    // if (data.faith) {
    //   apiData.faith = data.faith;
    //   arrayFields.push('faith');
    // }
    if (data.selectedTimes) {
      apiData.selectedTimes = JSON.stringify(processData(data.selectedTimes));
    }
    data.actionUrl = 'chaplain';
    data.actionMethod = 'post';
    data.apiData = apiData;
    data.arrayFields = arrayFields;
    console.log(apiData);
    // return;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Chaplain successfully created');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        const { data: resData } = resp.payload;
        setError(resData.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    charactersOnly
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <EmailInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    fieldName="email"
                    label="Email"
                    register={register}
                    errors={errors}
                    isRequired
                    placeHolder="Email"
                    resetCount={formIsReset}
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <PhoneNumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    placeHolder="Phone Number"
                    label="Phone Number"
                    fieldName="phone"
                    register={register}
                    errors={errors}
                    maxLength={15}
                    resetCount={formIsReset}
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <PasswordField
                    fieldName="password"
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Password"
                    register={register}
                    errors={errors}
                    placeHolder="Enter Password"
                    isRequired
                    minimLength={8}
                    eyeiconstyle={styles.eyeicon}
                    resetCount={formIsReset}
                    pattern={regexPattern}
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 flex_gap">
                  <label htmlFor="education">Educational Qualification</label>
                  <Controller
                    control={control}
                    name="education"
                    render={({ field: { onChange } }) => (
                      <MultInput
                        onChange={onChange}
                        placeholder="Enter Educational Qualification"
                      />
                    )}
                  />

                  {/* <TextInput
                    fieldName="qualification"
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    placeHolder="Enter Educational Qualification"
                    label="Educational Qualification"
                    register={register}
                    errors={errors}
                    maximLength={30}
                    isRequired
                    resetCount={formIsReset}
                    mandatory
                  /> */}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 flex_gap">
                  <label htmlFor="faith">Faith</label>
                  <Controller
                    control={control}
                    name="faith"
                    render={({ field: { onChange } }) => (
                      <MultInput onChange={onChange} placeholder="Enter Faith" />
                    )}
                  />
                  {/* <TextInput
                    fieldName="faith"
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    placeHolder="Enter Faith"
                    label="Faith"
                    register={register}
                    errors={errors}
                    maximLength={20}
                    isRequired
                    resetCount={formIsReset}
                    mandatory
                  /> */}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextAreaField
                    fieldName="bio"
                    label="Bio"
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    register={register}
                    errors={errors}
                    placeHolder="Enter Bio"
                    maxLength={300}
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 choose_upload">
                  <FileInput
                    control={control}
                    fieldName="image"
                    fieldTitle="Display Picture"
                    rules={undefined}
                    setError={errorSetter}
                  />
                  {errors.image && <p style={{ color: 'red' }}>{errors.image}</p>}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12">Available time slots</div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="timezone"
                    label="Time zone"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={timezone}
                    errors={errors}
                    placeholder="Choose Timezone"
                    resetCount={formIsReset}
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <MultiSelect
                    name="days"
                    label="Days of week"
                    control={control}
                    values={daysOfweek}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {selectedDaysWatch &&
                    selectedDaysWatch.map((item) => {
                      const foudItem = daysOfweek.find((x) => x.value === item);
                      return (
                        <TimeSelected
                          item={foudItem}
                          register={register}
                          key={item}
                          unregister={unregister}
                          errors={errors}
                          trigger={trigger}
                          getValues={getValues}
                          setError={errorSetter}
                        />
                        // <TimeSelectedV2
                        //   fields={fields}
                        //   remove={remove}
                        //   register={register}
                        //   append={append}
                        //   item={foudItem}
                        //   key={idx}
                        // />
                      );
                    })}
                </div>
              </div>
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting}
                value={submiting ? 'Please wait...' : 'Submit'}
              />
              <input
                className={styles.resetbtn}
                type="button"
                value="Reset"
                onClick={() => {
                  setFormIsReset(true);
                  reset();
                }}
              />
              {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChaplainCreate;

function TimeSelectedV2({ fields, remove, append, register, item }) {
  const handleAdd = () => {
    append({});
  };
  return (
    <>
      {fields.map((itm, index) => (
        <div key={itm.id}>
          <label htmlFor={item.value}>{item.label}</label>
          <input
            type="time"
            {...register(`selectedTime.${index}.start`, {
              required: {
                value: true,
                message: `Start time is required.`,
              },
            })}
            id={item.value}
          />
          <input
            type="time"
            {...register(`selectedTime.${index}.end`, {
              required: {
                value: true,
                message: `End time is required.`,
              },
            })}
            id={item.value}
          />
          <button type="button" onClick={() => remove(index)}>
            x
          </button>
        </div>
      ))}
      <button type="button" onClick={() => handleAdd()}>
        add more +
      </button>
    </>
  );
}

function TimeSelected({ item, register, unregister, errors, trigger, getValues, setError }) {
  const [state, setState] = useState([`item-${Math.random()}`]);
  const handleAdd = () => {
    setState([...state, `item-${Math.random()}`]);
  };
  const handleRemove = (start, end, index) => {
    const newItems = [...state];
    newItems.splice(index, 1);
    unregister(start);
    unregister(end);
    setState(newItems);
  };
  const validateStart = (v, end) => {
    const endValue = getValues(end);
    if (endValue) {
      const endTime = moment(endValue, 'HH:mm');
      const startTime = moment(v, 'HH:mm');
      if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
        return 'Start time must before end time';
      }
    } else {
      setError(end, 'Start time required');
    }
    const a = 10;
    if (a < 10) {
      trigger(end);
    }
  };
  const validateEnd = (v, start) => {
    const startValue = getValues(start);
    if (startValue) {
      const startTime = moment(startValue, 'HH:mm');
      const endTime = moment(v, 'HH:mm');
      if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
        return 'End time must after Start time';
      }
    } else {
      setError(start, 'Start time required');
    }
  };
  return (
    <div className="col-lg-12 md-2 map_date">
      {state.map((value, index) => {
        const startFieldId = `selectedTimes.${item.value}.${value}.start`;
        const endFieldId = `selectedTimes.${item.value}.${value}.end`;
        return (
          <div key={value} className="map_inner">
            <div className="map_box">
              <label htmlFor={item.value}>{item.label}</label>
              <input
                type="time"
                {...register(startFieldId, {
                  validate: {
                    isValid: (v) => validateStart(v, endFieldId),
                  },
                  required: {
                    value: true,
                    message: `Start time is required.`,
                  },
                })}
                id={item.value}
              />
              <input
                type="time"
                {...register(endFieldId, {
                  validate: {
                    isValid: (v) => validateEnd(v, startFieldId),
                  },
                  required: {
                    value: true,
                    message: `End time is required.`,
                  },
                })}
                id={item.value}
              />
              {state.length > 1 && (
                <button type="button" onClick={() => handleRemove(startFieldId, endFieldId, index)}>
                  x
                </button>
              )}
            </div>
            {errors['selectedTimes'] && <ErrorShow name={startFieldId} errors={errors} />}

            {errors['selectedTimes'] && <ErrorShow name={endFieldId} errors={errors} />}
          </div>
        );
      })}
      <button type="button" onClick={() => handleAdd()}>
        add more +
      </button>
    </div>
  );
}
const ErrorShow = ({ errors, name }) => {
  const arraysfields = name.split('.');
  let arr = errors;
  arraysfields.forEach((element) => {
    if (arr && arr[element]) {
      arr = arr[element];
    } else {
      arr = null;
    }
  });
  return arr ? <p style={{ color: 'red' }}>{arr.message}</p> : null;
};

ErrorShow.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
};
TimeSelectedV2.propTypes = {
  fields: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

TimeSelected.propTypes = {
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
