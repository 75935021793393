/* eslint-disable react/function-component-definition */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { TableContext } from '../../contexts/tableContext';
import styles from './TableViewPopup.module.scss';

const CustomPopup = React.memo(({ handleClose, children }) => {
  const { formthemeStyle } = useContext(TableContext);

  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          x
        </span>

        <Container fluid>{children}</Container>
      </div>
    </div>
  );
});

CustomPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};
CustomPopup.defaultProps = {
  children: null,
};
export default CustomPopup;
