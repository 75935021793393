/* eslint-disable react/forbid-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorShow from '../ErrorShow/ErrorShow';

export default function TimeSelected({
  item,
  register,
  unregister,
  errors,
  trigger,
  getValues,
  setValue,
  setError,
  value,
  actionStatus,
  // actionPerfomer,
}) {
  const [state, setState] = useState({ track: [`item-${Math.random()}`], isInitiated: false });
  const handleAdd = () => {
    setState({ ...state, track: [...state.track, `item-${Math.random()}`] });
  };
  const handleRemove = (start, end, index) => {
    const newItems = [...state.track];
    newItems.splice(index, 1);
    unregister(start);
    unregister(end);
    setState({ ...state, track: newItems });
  };
  const validateStart = (v, end) => {
    const endValue = getValues(end);
    if (endValue) {
      const endTime = moment(endValue, 'HH:mm');
      const startTime = moment(v, 'HH:mm');
      if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
        return 'Start time must before end time';
      }
    } else {
      setError(end, 'Start time required');
    }
    const a = 10;
    if (a < 10) {
      trigger(end);
    }
  };
  const validateEnd = (v, start) => {
    const startValue = getValues(start);
    if (startValue) {
      const startTime = moment(startValue, 'HH:mm');
      const endTime = moment(v, 'HH:mm');
      if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
        return 'End time must after Start time';
      }
    } else {
      setError(start, 'Start time required');
    }
  };
  useEffect(() => {
    if (value && Object.keys(value).includes(String(item.value)) && !state.isInitiated) {
      const currentChoosen = item.value;
      const allChoosenVlues = value[currentChoosen]['item-0'];
      const stateUparr = [];
      const arrLength = Object.keys(allChoosenVlues);
      if (state.track.length !== arrLength.length) {
        while (stateUparr.length < arrLength.length - 1) {
          stateUparr.push(`item-${Math.random()}`);
        }
        setState({ ...state, track: [...state.track, ...stateUparr], isInitiated: true });
      }
    }
  }, [value]);

  useEffect(() => {
    const currentChoosen = item.value;
    if (
      value &&
      Object.keys(value).includes(String(currentChoosen)) &&
      !actionStatus[currentChoosen]
    ) {
      const allChoosenVlues = value[currentChoosen]['item-0'];
      const arrLength = Object.keys(allChoosenVlues);
      if (state.track.length === arrLength.length) {
        Object.keys(allChoosenVlues).forEach((index, idx) => {
          let startKey = 'selectedTimes';
          let endKey = 'selectedTimes';
          const stateValue = state.track[idx];
          const valarr = stateValue.split('.');
          const startValue = allChoosenVlues[index].start;
          const endValue = allChoosenVlues[index].end;
          startKey = `${startKey}.${currentChoosen}.${valarr[0]}.${valarr[1]}.start`;
          endKey = `${endKey}.${currentChoosen}.${valarr[0]}.${valarr[1]}.end`;
          setValue(startKey, startValue);
          setValue(endKey, endValue);
        });
      }
    }
  }, [state.track]);
  return (
    <div className="col-lg-12 md-2 map_date">
      {state.track.map((val, index) => {
        const startFieldId = `selectedTimes.${item.value}.${val}.start`;
        const endFieldId = `selectedTimes.${item.value}.${val}.end`;
        return (
          <div key={val} className="map_inner">
            <div className="map_box">
              <label htmlFor={item.value}>{item.label}</label>
              <input
                type="time"
                {...register(startFieldId, {
                  validate: {
                    isValid: (v) => validateStart(v, endFieldId),
                  },
                  required: {
                    value: true,
                    message: `Start time is required.`,
                  },
                })}
                id={item.value}
              />
              <input
                type="time"
                {...register(endFieldId, {
                  validate: {
                    isValid: (v) => validateEnd(v, startFieldId),
                  },
                  required: {
                    value: true,
                    message: `End time is required.`,
                  },
                })}
                id={item.value}
              />
              {state.track.length > 1 && (
                <button type="button" onClick={() => handleRemove(startFieldId, endFieldId, index)}>
                  x
                </button>
              )}
            </div>
            {errors['selectedTimes'] && <ErrorShow name={startFieldId} errors={errors} />}
            {errors['selectedTimes'] && <ErrorShow name={endFieldId} errors={errors} />}
          </div>
        );
      })}
      <button type="button" onClick={() => handleAdd()}>
        add more +
      </button>
    </div>
  );
}

TimeSelected.propTypes = {
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  value: PropTypes.object,
  actionStatus: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // actionPerfomer: PropTypes.func.isRequired,
};
TimeSelected.defaultProps = {
  value: undefined,
  setValue: undefined,
};
