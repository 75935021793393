/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
import PropTypes from 'prop-types';

const ErrorShow = ({ errors, name }) => {
  const arraysfields = name.split('.');
  let arr = errors;
  arraysfields.forEach((element) => {
    if (arr && arr[element]) {
      arr = arr[element];
    } else {
      arr = null;
    }
  });
  return arr ? <p style={{ color: 'red' }}>{arr.message}</p> : null;
};

ErrorShow.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
};
export default ErrorShow;
