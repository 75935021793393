import React, { Suspense, lazy } from 'react';
import { SpinnerDotted } from 'spinners-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Outerlayout from '../layouts/outerLayout';
import Innerlayout from '../layouts/innerLayout';
import { getCookies, removeCookies } from '../hooks/useCookies';
import Settings from '../pages/settings/settings';
import ViewUsers from '../pages/users/viewUser';
import UserEdit from '../pages/users/editUser';
// import UserIndex from '../pages/users/index';
import UserCreate from '../pages/users/createUser';
import Preferences from '../pages/settings/preferences';
import CreateRoles from '../pages/roles/createRoles';
import RolesIndex from '../pages/roles/index';
import EditRoles from '../pages/roles/editRoles';
import SettingsApi from '../pages/settings/SettingsApiPage';
import SettingsLayout from '../pages/settings/settingsLayout';
import PageNotFound from '../pages/404/pageNotFound';
import PermissionDenied from '../pages/permission/permission';
import ProfileLayout from '../pages/profile/profileLayout';
import ChaplainCreate from '../pages/chaplains/create';
import ViewChaplain from '../pages/chaplains/view';
import ChaplainEdit from '../pages/chaplains/edit';
import SessionHistoryIndex from '../pages/session history';
import ChaplainChange from '../pages/chaplainchange';
import ViewChangeChaplain from '../pages/chaplainchange/view';
import ContactUsIndex from '../pages/contactus';
import ViewContactRequest from '../pages/contactus/view';
import PaymentHistoryIndex from '../pages/payment history';
import ViewPaymentHistory from '../pages/payment history/view';

const Login = lazy(() => import('../pages/logIn/logIn'));
const UserIndex = lazy(() => import('../pages/users/index'));
const UserVerification = lazy(() => import('../pages/verify/userVerfication'));
const Register = lazy(() => import('../pages/register/register'));
const Forgetpassword = lazy(() => import('../pages/passwords/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/passwords/resetPassword'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import('../pages/profile/profilepage'));
const ChangePassword = lazy(() => import('../pages/profile/changePassword'));
const ProfileEdit = lazy(() => import('../pages/profile/profileEdits'));
const ViewRole = lazy(() => import('../pages/roles/view'));
const ChaplainIndex = lazy(() => import('../pages/chaplains/index'));
const AppUserIndex = lazy(() => import('../pages/appuser/index'));
const AppUserView = lazy(() => import('../pages/appuser/view'));
const ReportsIndex = lazy(() => import('../pages/reports/index'));
const ReportsView = lazy(() => import('../pages/reports/view'));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reports"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReportsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/sessionhistory"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SessionHistoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reports/viewdetails/:reportsId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReportsView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appuser"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AppUserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appuser/viewdetails/:appuserId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AppUserView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplain"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ChaplainIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplainchange"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ChaplainChange />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplainchange/viewdetails/:chRequestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewChangeChaplain />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/contactrequest"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ContactUsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/contactrequest/viewdetails/:ctRequestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewContactRequest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/paymenthistory"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <PaymentHistoryIndex />
              </Suspense>
            }
          />
          <Route
            exact
            path="/paymenthistory/viewdetails/:paymentHistoryId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ViewPaymentHistory />
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplain/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ChaplainCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplain/history/:chplainId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SessionHistoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplain/edit/:chplainId/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ChaplainEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/chaplain/viewdetails/:chplainId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewChaplain />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                  />
                </div>
              }
            >
              {' '}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {' '}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies('Token')) {
    if (getCookies('USERPERMISSION')) {
      return children;
    }
    removeCookies('Token');
    removeCookies('refreshToken');
    sessionStorage.setItem('cookiesCleared', true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem('cookiesCleared', true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies('Token') ? <Navigate to="/dashboard" /> : children;
}
